body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #f5f5f5;
}
.title{
  text-align: center;
}
.leaderboard-card{
  margin: 10px;
}
.card-title{
  background-color: aliceblue;
}
.add-league-button{
  position: fixed;
  bottom: 74px;
  right: 18px;
}
.bottom-menu{
  bottom: 0;
  position: fixed;
  width: 100%;
}

