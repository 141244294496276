.add-league-modal {

}

.modal-content {
    border: none;
}

.add-league-modal__button {
    margin: 15px 0;
}

.add-league-modal .modal-dialog {
    top: 25%;
}
.modal-header{
    background-color: #3f51b5;
    color: white;
}

.close{
    color: white;
    opacity: 1;
}

.modal { overflow-y: auto }