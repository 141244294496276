.spinner {
    position: absolute;
    z-index: 100;
    height: 100%;
    width: 100%;
    top: 0;
}
.cssload-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin:0px auto;
    background: rgba(204, 204, 204, 0.7);
}

.cssload-container .cssload-item {
    top: 0%;
    margin: auto;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    width: 49px;
    height: 49px;
    background-color: rgb(255,255,255);
    box-shadow: 0 0 8px 1px rgba(0,0,0,0.25);
}

.cssload-container .cssload-ventilator {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    -o-border-radius: 50%;
    -ms-border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    animation: cssload-spin 1.15s ease-in-out infinite reverse;
    -o-animation: cssload-spin 1.15s ease-in-out infinite reverse;
    -ms-animation: cssload-spin 1.15s ease-in-out infinite reverse;
    -webkit-animation: cssload-spin 1.15s ease-in-out infinite reverse;
    -moz-animation: cssload-spin 1.15s ease-in-out infinite reverse;
    transition: all 0.23s ease;
    -o-transition: all 0.23s ease;
    -ms-transition: all 0.23s ease;
    -webkit-transition: all 0.23s ease;
    -moz-transition: all 0.23s ease;
}

.cssload-container .cssload-ventilator:before, .cssload-container .cssload-ventilator:after {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgb(255,255,255);
    box-sizing: border-box;
    -o-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    border-radius: 50%;
    -o-border-radius: 50%;
    -ms-border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    box-shadow: 0 0 8px 1px rgba(0,0,0,0.25);
    -o-box-shadow: 0 0 8px 1px rgba(0,0,0,0.25);
    -ms-box-shadow: 0 0 8px 1px rgba(0,0,0,0.25);
    -webkit-box-shadow: 0 0 8px 1px rgba(0,0,0,0.25);
    -moz-box-shadow: 0 0 8px 1px rgba(0,0,0,0.25);
    animation: cssload-shapeit 0.58s ease infinite alternate;
    -o-animation: cssload-shapeit 0.58s ease infinite alternate;
    -ms-animation: cssload-shapeit 0.58s ease infinite alternate;
    -webkit-animation: cssload-shapeit 0.58s ease infinite alternate;
    -moz-animation: cssload-shapeit 0.58s ease infinite alternate;
    transition: all 0.23s ease;
    -o-transition: all 0.23s ease;
    -ms-transition: all 0.23s ease;
    -webkit-transition: all 0.23s ease;
    -moz-transition: all 0.23s ease;
    content: '';
}
.cssload-container .cssload-ventilator:before {
    left: -125%;
    border-left: 2px solid #3f51b5;
}
.cssload-container .cssload-ventilator:after {
    right: -125%;
    border-right: 2px solid #3f51b5;
}





@keyframes cssload-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@-o-keyframes cssload-spin {
    from {
        -o-transform: rotate(0deg);
    }
    to {
        -o-transform: rotate(360deg);
    }
}

@-ms-keyframes cssload-spin {
    from {
        -ms-transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
    }
}

@-webkit-keyframes cssload-spin {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}

@-moz-keyframes cssload-spin {
    from {
        -moz-transform: rotate(0deg);
    }
    to {
        -moz-transform: rotate(360deg);
    }
}

@keyframes cssload-shapeit {
    from {
        border-radius: 50%;
    }
    to {
        border-radius: 0px;
    }
}

@-o-keyframes cssload-shapeit {
    from {
        border-radius: 50%;
    }
    to {
        border-radius: 0px;
    }
}

@-ms-keyframes cssload-shapeit {
    from {
        border-radius: 50%;
    }
    to {
        border-radius: 0px;
    }
}

@-webkit-keyframes cssload-shapeit {
    from {
        border-radius: 50%;
    }
    to {
        border-radius: 0px;
    }
}

@-moz-keyframes cssload-shapeit {
    from {
        border-radius: 50%;
    }
    to {
        border-radius: 0px;
    }
}