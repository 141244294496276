.terms-modal__form {
    padding: 20px;
}

.terms-modal__form h3, .terms-modal__form h4 {
    margin-top: 30px;
}

.terms-modal .modal-dialog {
    top: 2%;
}