.header__title {
    background-color: #3f51b5;
    color: white;
    height: 50px;
    text-align: center;
    padding-top: 2px;
    font-size: 28px;
    font-weight: 400;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1;
    border: 1px solid black;
}