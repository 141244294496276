.login {
  margin: 0 auto;
  max-width: 500px;
  margin-top: 50px;
}

.login__image {
  width: 50%;
  margin-bottom: 20px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.social-btn {
  color: white;
}

.btn-facebook {
  background-color: #3b5998;
  border: none;
}

.btn-google {
  background-color: #db4437;
  border: none;
}

form div.clearfix a.text-btn {
  text-decoration: underline;
}

.or-seperator {
  margin: 30px 0 10px;
  text-align: center;
  border-top: 1px solid #ccc;
}

.or-seperator i {
  padding: 0 10px;
  background: #f7f7f7;
  position: relative;
  top: -11px;
  z-index: 1;
}

.terms-and-conditions {
  text-decoration: underline;
  color: #3f51b5;
  cursor: pointer;
}

.social-btn .btn {
  margin: 10px 0;
  font-size: 15px;
  text-align: left;
  line-height: 24px;
}

.social-btn .btn i {
  float: left;
  margin: 4px 20% 0 5px;
  min-width: 15px;
}

.input-group-addon .fa {
  font-size: 18px;
}

.input-group-addon {
  padding: 9px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #555;
  text-align: center;
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.login__error-message {
  color: red;
  margin-bottom: 10px;
}
@media only screen and (min-width: 550px) {
  .login {
  }
}
