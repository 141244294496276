.profile {
  margin: 0 auto;
  max-width: 500px;
  text-align: center;
  margin-top: 90px;
}

.profile__header {
  background-color: #99cfff;
  color: white;
  padding-bottom: 40px;
}

.profile__header-title {
  place-content: space-between;
  font-size: 30px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 20px 0;
}

.profile__header-image img {
  max-width: 130px;
  border-radius: 100px;
  vertical-align: middle;
  background-color: white;
  border-style: none;
}

.profile .logout-btn {
  margin-top: 60px;
}
