.home {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 90px); /* Adjust this if you have a header or footer */
  margin-top: 90px;
  overflow: hidden;
}

.cards-container {
  flex-grow: 1;
  overflow-y: auto;
  padding-bottom: 80px; /* To ensure last card is fully visible */
}

.home__new-league-button {
  position: fixed;
  bottom: 70px;
  right: 10px;
  z-index: 1000; /* Ensure button is above scrollable content */
}

.app-body .card__component {
  position: relative;
  border: black 1px solid;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 40px;
  border-radius: 2px;
}

.card__title {
  text-align: center;
  color: white;
  background: #3f51b5;
  font-size: 20px;
  padding: 10px;
}

.card__description {
  text-align: center;
  padding: 20px;
}

.card__cta-container {
  text-align: center;
  padding: 0 20px 20px;
}

.card__cta-container svg {
  margin-bottom: 1px;
}

@media only screen and (min-width: 550px) {
  .app-body .card__component {
    max-width: 500px;
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
  }
}

/* Add smooth scrolling */
.cards-container {
  scroll-behavior: smooth;
}
