.league {
  background-color: blue;
}

.leaderboard {
  text-align: center;
  margin-top: 90px;
}

.leaderboard__header {
  background-color: #99cfff;
  color: white;
}

.leaderboard__header-title {
  display: flex;
  place-content: space-between;
  font-size: 30px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 20px 0;
}

.leaderboard__header-image img {
  max-width: 130px;
  border-radius: 100px;
  vertical-align: middle;
  background-color: white;
  border-style: none;
}

.leaderboard__header-icon svg {
  border-radius: 50%;
  font-size: 45px;
  margin-right: 5px;
  cursor: pointer;
  background: white;
  color: black;
  padding: 5px 8px 5px 5px;
}

.leaderboard__header-empty {
  width: 50px;
}

.leaderboard__header-description {
  padding: 10px 0;
}

.leaderboard__table {
  width: 100%;
}

.leaderboard__table-row {
  border: 1px black solid;
  height: 60px;
}

.leaderboard__table-row.selected-user {
  background-color: #43f66e;
  border: 1px blue solid;
  box-shadow: 0 0 0 1px blue inset;
}

.leaderboard__table-row--number {
  width: 10%;
}

.leaderboard__table-row--image {
  width: 17%;
}

.leaderboard__table-row--name {
  width: 40%;
}

.leaderboard__table-row--elo {
  width: 16%;
  text-align: right;
}

.leaderboard__table-row--icon {
  width: 17%;
}

.leaderboard__table img {
  max-width: 50px;
}

.leaderboard__new-results-button {
  position: fixed;
  bottom: 70px;
  right: 10px;
}

.leaderboard__edit-league-button {
  position: fixed;
  bottom: 70px;
  left: 10px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}

@media only screen and (min-width: 550px) {
  .leaderboard {
    margin: auto;
    margin-top: 90px;
    max-width: 500px;
    text-align: center;
  }
}
